.error-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(0, 0, 0);
    height: 100%;
}

.error-title {
    font-size: 3rem;
    color: orange;
}

.error-text {
    color: orange;
}

.emot-wrapper {
    font-size: 4rem;
    color: orange;
}