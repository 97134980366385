.button-tag {
    cursor: pointer;
    font-size: 8px;
    padding: 1px;
}

.sub-button {
    margin: 2px 0;
}

.button-tag:hover {
    border-style: double;
    border-color: orange;
    transform: scale(1.05);
}

@media screen and (min-width: 576px) {
    .button-tag {
        font-size: 10px;
    }
   
}

@media screen and (min-width: 768px) {
    .button-tag {
        font-size: 12px;
    }
}