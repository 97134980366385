.portfolio {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0 2rem 0;
    background: #1A1C23;
}

.port-folio-title {
    align-self: center;
}


.table-wrapper {
    display: flex;
    flex-direction: column;
}

.cards-wrapper {
    display: grid;
    grid-gap: 1rem;
    overflow-x: scroll;
    padding: 0 0 1rem 1rem;
}

.port-folio-title {
    color: white;
    font-size: 2rem;
}


@media screen and (min-width: 576px) {

    .filter-buttons-wrapper {
        width: 95%;
    }
}

@media screen and (min-width: 768px) {
    .cards-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        overflow: auto;
    }

    .table-wrapper {
        align-items: center;
        margin: 4rem 0 4rem 0;
    }

    .filter-buttons-wrapper {
        width: 700px;
        align-self: center;
    }

    .portfolio {
        padding: 5rem 0 5rem 0;
    }
}

@media screen and (min-width: 1400px) {
    .cards-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
    }
}