/* Wrapper */

.image-wrapper {
    position: relative;
}

/* icons */

.tech-icon {
    width: auto;
    height: 50px;
}

.tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    visibility:hidden;
    opacity: 0.9;
    width: 0;
    height: 0;
    top: -5px;
    left: 0;
    background: white;
    color: black;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    padding: 5px;
}

.image-wrapper:active > .tooltip {
    visibility: visible;
    width: 100%;
    height: 101%;
    cursor: pointer;
}


@media screen and (min-width: 768px) {
    .tech-icon {
        width: auto;
        height: 55px;
    }

    .tooltip {
        font-size: 12px;
    }

    .image-wrapper:hover > .tooltip {
       visibility: visible;
       width: 100%;
       height: 101%;
       cursor: pointer;
    }
}


@media screen and (min-width: 992px) {
    .tech-icon {
        width: auto;
        height: 60px;
    }
}