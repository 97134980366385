.card {
    grid-row: 1;
    position: relative;
}


.thumbail {
    width: 300px;
    height: 200px;
    object-fit:fill;
}

.type-badge {
    position: absolute;
    bottom: 10px;
    right: 5px;
    font-size: 8px;
    color: white;
    padding: 10px;
    border-radius: 10% 30% 50% 70%;
}

.badge-background-orange {
    background: orange;
}

.badge-background-red {
    background: red;
}

.badge-background-green {
    background: green;
}

.tags-wrapper {
    display: flex;
}

.tags-wrapper > p {
    margin-left: 3px;
    margin-right: 3px;
}

.tags-wrapper :first-child {
    margin: 0 3px 0 0;
}

.tags-wrapper :last-child {
    margin: 0 0 0 3px;
}

.card-title {
    margin: 0;
    color: white;
}

@media screen  and (min-width: 576px) {
    .thumbail {
        width: 350px;
        height: 210px;
    }
}

@media screen and (min-width: 768px) {
    .card {
        grid-row: unset;
    }
}

@media screen and (min-width: 992px) {
    .thumbail {
        width: 450px;
        height: 300px;
    }

    .type-badge {
        font-size: 10px;
        padding: 12px;
    }
    
}