.tag {
    margin: 0;
    font-size: 8px;
    color: white;
}

@media screen and (min-width: 768px) {
    .tag {
        font-size: 10px;
    }
}

@media screen and (min-width: 992px) {
    .tag {
        font-size: 12px;
    }
}