.filter-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 5rem 0;
    margin-bottom:  2.5rem;
}

.cat-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px 2px 5px 2px;
    width: 100%;
}

.buttons-wrapper {
    position:absolute;
    top: 80%;
    left: 0;
    padding: 5px 2px 5px 2px;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    width: 0;
    height: 0;
    z-index: 1;
}

.reset-button{
    cursor: pointer;
    padding: 0;
    font-size: 25px;
    border: none;
    width: 25px;
    height: 25px;
    background: none;
    color: white;
}

.reset-button:active {
    transform: rotate(180deg);
}

.main-button {
    padding: 5px 5px 5px 5px;
    white-space: nowrap;
}


.show {
    visibility:visible;
    width: 100%;
    height: auto;
}

@media screen and (min-width: 576px) {
    .filter-menu {
        width: 90%;
        padding-bottom: 6rem;
        align-self:center;
    } 
   
}

@media screen and (min-width: 768px) {
    .filter-menu {
        max-width: 700px;
    } 
   
    .cat-wrapper {
        padding: 5px 5px 5px 5px;
    }

    .buttons-wrapper {
        padding: 5px 5px 5px 5px;
    }
}


@media screen and (min-width: 992px) {
    
    .buttons-wrapper {
        top: 100%;
    }
}