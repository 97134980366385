.main-nav {
    display: flex;
}

.main-nav > .link-wrapper {
    display: flex;
    flex-direction: column;
}

.main-nav :first-child {
    margin: 0 5px 0 0;
}

.main-nav :last-child {
    margin: 0 0 0 5px;
}

.link-wrapper > a {
    color: orange;
    text-decoration: none;
}

.link-wrapper > .underline {
    height: 1px;
    margin: 0 5px 0 0;
    background: orange;
}

.social-nav-vert {
    position:fixed;
    top: calc(50vh - 100px);
    left: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    padding: 5px;
}

.social-nav-vert > a {
    margin: 0;
    font-size: 2rem;
    color: orange;
}

.social-nav > a {
    margin: 0 5px 0 5px;
    font-size: 2rem;
    color: orange;
}

.social-nav :first-child {
    margin: 0 5px 0 0;
}

.social-nav :first-child {
    margin: 0 0 0 5px;
}