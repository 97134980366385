@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');


* {box-sizing:border-box}

html {
  font-size: 16px;
}

body {
  position: relative;
    margin: 0;
    font-family: 'Roboto', 'Open Sans','Times New Roman', Times, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.content {
  display: flex;
  flex-direction: column;
}

.content-strech {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.display-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} 

.center-wrapp {
  align-items: center;
}