.main-header {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem 1rem 2rem;
    background: #23252F;
    z-index: 1;
}

.main-header > a {
    text-decoration: none;
}


.header-title {
    color: orange;
    font-family: 'Roboto';
}

@media screen and (min-width: 576px) {
    .main-header {
        flex-direction: row;
    }
}