.project {
    flex: 1;
    background: #1A1C23;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 0;
}


.project-subtitle {
    font-size: 1.1rem;
    text-align: center;
    width: 95%;
}

.project-thumbail {
    width: 90%;
    height: 80%;
    border-style: double;
    padding: 3px;
    object-fit:fill;
}

.project-skills {
   padding: 0 1rem 0 1rem;
}

.project-describ {
    width: 90%;
}

.project-skills-title {
    font-size: 1.1rem;
    font-weight: 700;
}

.li-wrapper {
    display: flex;
    margin: 10px 0 10px 0;
}

.li-wrapper > span {
    margin-right: 5px;
}

.li-wrapper > li {
    list-style: none;
}

.project-links {
    display: flex;
    justify-content: center;
}

.project-links > a {
    color: white;
    font-size: 2rem;
    margin: 0 2px 0 2px;
}

@media screen and (min-width: 576px) {
    .project {
        padding: 2rem 0 2rem 0;
    }
    
    .project-thumbail {
        width: 80%;
        height: 70%;
    }
}

@media screen and (min-width: 992px) {
    .project-thumbail {
        width: 790px;
        height: 502px;
    }
    
    .project-subtitle {
        font-size: 1.1rem;
        text-align: center;
        width: 900px;
    }

    .project-describ {
        width: 900px;
    }

    .project {
        padding: 4rem 0 4rem 0;
    }
}