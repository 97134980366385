.home {
    display: flex;
    flex-direction: column;
}

.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../assets/small/pexels-markus-spiske-965345\(1\).jpg');
    height: 300px;
}

/**sections*/

.article {
    padding: 0 0 0 0;
    flex: 1;
    background: #23252F;
}

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 1rem 0;
}

.tech {
    background: #23242b;
    padding: 0 0 50px 0;
}

.tech-stats-wrapper{
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0 2rem 0;
}

/**texts wrappers*/

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-text-wrapper {
    background: rgba(255, 255, 255, 0.9);
    margin-left: 0;
    padding: 20px 20px 20px 20px;
}

.about-text-wrapper {
    width: 95%;
}

/**titles*/

.hero-title {
    font-size: 2.5rem;
    color: orange;
    font-family: 'Roboto';
    margin: 0;
}

.section-title {
    font-size: 2rem;
    color: white;
    font-family: 'Roboto';
}

/**texts*/
.hero-text {
    font-size: 1.5rem;
    color: orange;
}

.section-text {
    color: white;
}

/**miscellaneous*/

.invert {
    flex-direction: column-reverse;
}

.margin-top-10 {
    margin-top: 10rem;
}

.line {
    background: white;
    margin: 10px 0 10px 0;
    width: 95%;
}

.line-short {
    height: 2px;
}

.line-long {
    height: 2px;
    max-width: 368px;
}

.skills-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-row-gap: 1rem;
    align-items: center;
    justify-items: center;
    width: 90%;
    color: white
}


@media screen and (min-width: 576px) {

    .section {
        padding: 2rem 0 2rem 0;
    }
    
    .about-text-wrapper {
        width: 80%;
        max-width: 700px;
    }

    .tech-stats-wrapper{
        width: 80%;
        max-width: 700px;
    }


    .hero-text-wrapper {
        margin-left: 3vw;
    }

    .section-title {
        font-size: 2.5rem;
    }

}


@media screen and (min-width: 786px) {
    .hero {
        background-image: url('../../assets/medium/pexels-markus-spiske-965345\(1\).jpg');
        height: 600px;
    }

    .section {
        padding: 3rem 0 3rem 0;
    }

    .skills-wrapper {
        max-width: 700px;
    }

    .article {
        padding: 10rem 0 2rem 0;
    }

    .tech {
        padding: 2rem 0 5rem 0;
    }

    .text-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .hero-text-wrapper {
        flex-direction: column;
        width: 600px;
        margin-left: 6vw;
        padding: 4rem 0 4rem 0;
    }


    .about-text-wrapper {
        width: 80%;
    }

    .tech-stats-wrapper{
        width: 80%;
        margin: 3rem 0 3rem 0;
    }

    .section-title {
        margin: 3rem 0 3rem 0;
    }

    .line {
        align-self:center;
        margin: 0 10px 0 10px;
        width: 2px;
    }

    .line-short {
        height: 300px;
    }

    .line-long {
        margin-top: 20px;
        width: 50%;
        max-width: 560px;
        height: 2px;
        align-self: center;
    }

}


@media screen and (min-width: 992px) {
    .hero {
        justify-content: flex-start;
        background-image: url('../../assets/large/pexels-markus-spiske-965345\(1\).jpg');
        height: 800px;
    }

    .hero-text-wrapper {
        padding: 7rem 0 7rem 0;
    }

    .section {
        padding: 4rem 0 4rem 0;
    }

    .tech-icon {
        width: auto;
        height: 60px;
    }

}

